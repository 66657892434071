import React from 'react'
import CategoryList from '../components/CategoryList'
import BannerProduct from '../components/BannerProduct'
import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'

const Home = () => {
  return (
    <div>
      <br/>
      <div><center>Мінімальне замовлення 810 грн.&nbsp;&nbsp;&nbsp;Доставка до 9 робочих днів.&nbsp;&nbsp;&nbsp;Оплата після отримання.</center></div>
      <CategoryList/>
      <BannerProduct/>

      <VerticalCardProduct category={"Машинки та транспорт"} heading={"Машинки та транспорт"}/>

      <VerticalCardProduct category={"м'які іграшки"} heading={"М'які іграшки"}/>
      <VerticalCardProduct category={"ляльки"} heading={"Популярні Ляльки"}/>

      <VerticalCardProduct category={"іграшки для пісочниці"} heading={"Іграшки для пісочниці"}/>
      <VerticalCardProduct category={"Тематичні ігрові набори"} heading={"Тематичні ігрові набори"}/>
      <VerticalCardProduct category={"іграшки для самих маленьких"} heading={"Іграшки для самих маленьких"}/>
      <VerticalCardProduct category={"активні ігри"} heading={"Активні ігри"}/>
      <VerticalCardProduct category={"треки, паркінги та ЖДs"} heading={"Треки, паркінги та ЖД"}/>
      <VerticalCardProduct category={"конструктори"} heading={"Конструктори"}/>
      <VerticalCardProduct category={"ігри-головоломки"} heading={"Ігри-головоломки"}/>
      <VerticalCardProduct category={"настільні ігри"} heading={"Настільні ігри"}/>
      <VerticalCardProduct category={"кубики"} heading={"Кубики"}/>
    </div>
  )
}
 
export default Home