const productCategory = [
    { id : 1, label : "М'які іграшки", value : "м'які іграшки"},
    { id : 2, label : "Активні ігри", value : "активні ігри"},
    { id : 3, label : "Треки, паркінги та ЖД", value : "треки, паркінги та ЖД"},
    { id : 4, label : "Іграшки для пісочниці", value : "іграшки для пісочниці"},
    { id : 5, label : "Тематичні ігрові набори", value : "Тематичні ігрові набори"},
  // { id : 6, label : "Printers", value : "printers"}, 
    { id : 7, label : "Кубики", value : "кубики"},
    { id : 8, label : "Ігри-головоломки", value : "ігри-головоломки"},
    { id : 9, label : "Конструктори", value : "конструктори"},
    { id : 10, label : "Настільні ігри", value : "настільні ігри"},
    { id : 11, label : "Іграшки для самих маленьких", value : "іграшки для самих маленьких"},
    { id : 12, label : "Ляльки", value : "ляльки"},
    { id : 13, label : "Машинки та транспорт", value : "Машинки та транспорт"},
]

 
export default productCategory